<div class="meeting-form">
  <ng-container *ngIf="user$ | async; else loading">
    <div class="login-form">
      <ng-container *ngIf="!user?.ID; else loggedIn">
        <div class="logged-out">
          <ng-container *ngIf="canJoinMeeting; else meetingUnavailable">
            <ng-container *ngIf="!showGuestForm; else guestMode">
              <h2 class="mb-3">Join meeting with LF Account</h2>
              <div class="flex justify-center items-center gap-2">
                <button (click)="onLogin()" [disabled]="skipAuth0()">Sign In</button>
                <i class="fa-solid fa-info-circle text-yellow-400 mb-3" pTooltip="Login is unavailable at this time."
                  *ngIf="skipAuth0()"></i>
              </div>
              <p *ngIf="!meeting.restricted">or join <a (click)="onToggleGuestMode()">as a guest</a></p>
              <p *ngIf="meeting.restricted">or join <a (click)="onToggleGuestMode()">with your email</a></p>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="create-account">
      <h2 *ngIf="user?.ID">Your LF Account allows you to:</h2>
      <h2 *ngIf="!user?.ID"><strong>Don't have an account?</strong> Creating an LF account only takes a moment,
        and you get
        the following benefits...</h2>

      <div class="benefits-list">
        <div class="benefit">
          <i class="fa-light fa-chart-column"></i>
          <span>Browse your contributions and community activities</span>
        </div>
        <div class="benefit">
          <i class="fa-light fa-video"></i>
          <span>Access to all meeting recordings and transcripts</span>
        </div>
        <div class="benefit">
          <i class="fa-light fa-envelope"></i>
          <span>Easily manage your communication preferences</span>
        </div>
      </div>

      <button *ngIf="(!user || !user?.ID) && !skipAuth0()" (click)="onLogin(true)">Create LF Account</button>
    </div>
  </ng-container>
</div>

<ng-template #loggedIn>
  <div class="logged-in">
    <h2>Welcome, <strong>{{ user?.Name }}</strong>!</h2>
    <span class="text-gray-400 italic mb-8">Not you? Click <a (click)="onLogin()">here</a> to
      login.</span>
    <ng-container *ngIf="canJoinMeeting; else meetingUnavailable">
      <ng-container *ngIf="zoomLink$ | async as zoomLink; else loadingButton">
        <div *ngIf="zoomLink.link !== 'error'">
          <p>Your meeting has been launched.</p>
          <p>Don't see your Zoom meeting? Click Join Meeting.</p>
          <a class="link-button" [href]="meetingLink">Join Meeting</a>
        </div>
        <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #meetingUnavailable>
  <div class="meeting-unavailable">
    <ng-container *ngIf="meetingEnded">
      <p class="font-bold">Meeting Start Time:</p>
      <p>{{ getMeetingStartTime() }}</p>
      <p class="text-red">Sorry, this meeting has ended.</p>
    </ng-container>
    <ng-container *ngIf="!meetingEnded">
      <p class="font-bold">Meeting Start Time:</p>
      <p>{{ getMeetingStartTime() }}</p>
      <ng-container>
        <div class="flex items-center gap-3" *ngIf="!showInvite">
          <i class="fa-light fa-clock text-3xl text-yellow-400"></i>
          <p>You may only join the meeting <span class="text-yellow-400 font-bold normal-case">up to {{
              meeting.early_join_time || 10 }} minutes</span>
            before the start time.</p>
        </div>
        <div *ngIf="showInvite" class="w-full flex flex-col gap-2" [formGroup]="inviteForm">
          <div class="form-control">
            <label>Full Name <span class="text-red">*</span></label>
            <input formControlName="name" type="text" placeholder="Enter Full Name...">
            <span class="text-red" *ngIf="inviteNameError">{{ inviteNameError }}</span>
          </div>
          <div class="form-control">
            <label>Email <span class="text-red">*</span></label>
            <input formControlName="email" type="email" placeholder="Enter Email Address...">
            <span class="text-red" *ngIf="inviteEmailError">{{ inviteEmailError }}</span>
          </div>
          <div class="form-action">
            <a (click)="showInvite = false">Cancel</a>
            <button (click)="onRequestInvite()" [disabled]="inviteFormDisabled">
              <span>Request </span>
              <i class="fa-light fa-circle-notch fa-spin" *ngIf="inviteFormLoading"></i></button>
          </div>
          <div class="form-error" *ngIf="inviteError">
            <p class="text-red">Failed to send invite. Please try again or <a [href]="supportLink"
                target="_blank">contact support</a> for further assistance.</p>
          </div>
        </div>
        <div class="flex justify-center mt-8" *ngIf="!showInvite">
          <button *ngIf="user" disabled="true">Join Meeting</button>
          <div *ngIf="!user" class="flex flex-col justify-items-center text-center">
            <h2 class="mb-3">Join meeting with LF Account</h2>
            <div class="flex justify-center items-center gap-2">
              <button (click)="onLogin()" [disabled]="skipAuth0()">Sign In</button>
              <i class="fa-solid fa-info-circle text-yellow-400 mb-3" pTooltip="Login is unavailable at this time."
                *ngIf="skipAuth0()"></i>
            </div>
            <p *ngIf="!meeting.restricted" class="cursor-default text-[#a3a3a3]" [pTooltip]="tenMinWarning"
              [tooltipOptions]="tooltipOptions">or join as a guest</p>
            <p *ngIf="meeting.restricted" class="cursor-default text-[#a3a3a3]" [pTooltip]="tenMinWarning"
              [tooltipOptions]="tooltipOptions">or join with your email</p>
          </div>
        </div>
        <div class="invite-link" *ngIf="!showInvite">
          <a *ngIf="meeting.visibility === 'public' && !inviteRequested" (click)="showInvite = true">Need an invite?</a>
          <span class="text-green-500" *ngIf="meeting.visibility === 'public' && inviteRequested">An invite has been
            sent to your email!</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #guestMode>
  <div class="guest-mode" [formGroup]="form">
    <h2 class="mb-3">Join meeting {{ !meeting.restricted ? 'as a guest' : 'with your email' }}</h2>

    <div class="form-control">
      <label>Name <span class="text-red">*</span></label>
      <input formControlName="name" type="text" placeholder="Enter Full Name...">
    </div>
    <div class="form-control">
      <label>Email <span *ngIf="meeting.restricted"><span class="text-red">*</span>
          <i class="fa-light fa-info-circle ml-2"
            pTooltip="Use the email address that you received the invite from"></i></span></label>
      <input formControlName="email" type="email" placeholder="Enter Email Address...">
    </div>
    <div class="form-control">
      <label>Organization</label>
      <input formControlName="organization" type="text" placeholder="Enter Organization...">
    </div>

    <div class="form-action">
      <a (click)="onToggleGuestMode()">Cancel</a>
      <button (click)="onJoinMeeting()" [disabled]="formDisabled">
        <span>{{ meetingLinkLoading ? 'Joining...' : 'Join Meeting' }} </span>
        <i class="fa-light fa-circle-notch fa-spin" *ngIf="meetingLinkLoading"></i></button>
    </div>

    <div *ngIf="meetingLink && meetingLink !== 'error' && meetingLink !== 'not-invited'">
      <p>Your meeting has been launched.</p>
      <p>Don't see your Zoom meeting? Click the link below.</p>
      <a [href]="meetingLink" target="blank">{{ meetingLink }}</a>
    </div>

    <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
  </div>
</ng-template>

<ng-template #loadingButton>
  <i class="fa-light fa-circle-notch fa-spin"></i>
</ng-template>

<ng-template #loading>
  <div class="flex justify-center items-center">
    <i class="fa-light fa-circle-notch fa-spin text-lg"></i>
  </div>
</ng-template>

<ng-template #errorMessage>
  <div *ngIf="meetingError">
    <p *ngIf="meetingErrorMessage === 'not-invited'" class="error">This meeting is private and only allows invited
      participants. If you received an invite, and are still getting this error, please ensure the invited email address
      is a verified email on your account: <a href="https://openprofile.dev" target="_blank">https://openprofile.dev</a>
    </p>

    <p *ngIf="meetingErrorMessage === 'invalid-email'" class="error">It looks like you entered an invalid email
      address. Please enter the correct email address and try again, or <a [href]="supportLink" target="_blank">contact
        support</a> for further assistance.</p>

    <p *ngIf="meetingErrorMessage === 'user-not-found'" class="error">We were unable to locate your user in our
      system. Please try again or <a [href]="supportLink" target="_blank">contact
        support</a> for further assistance.</p>

    <div *ngIf="meetingErrorMessage === 'past-meeting'" class="error">
      <p class="font-bold">Meeting Start Time:</p>
      <p>{{ getMeetingStartTime() }}</p>
      <p class="text-red">Sorry, this meeting has ended.</p>
    </div>

    <p class="error" *ngIf="meetingErrorMessage === 'error'">Failed to get meeting link. Please try again or <a
        [href]="supportLink" target="_blank">contact
        support</a> for further assistance.</p>
  </div>
</ng-template>
