import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthGuard, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@environments/environment';
import { TooltipModule } from 'primeng/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './modules/auth/auth.component';
import { MeetingFormComponent } from './modules/join-meeting/components/meeting-form/meeting-form.component';
import { MeetingHeaderComponent } from './modules/join-meeting/components/meeting-header/meeting-header.component';
import { JoinMeetingComponent } from './modules/join-meeting/join-meeting.component';
import { MeetingNotAuthorizedComponent } from './modules/meeting-not-authorized/meeting-not-authorized.component';
import { MeetingNotFoundComponent } from './modules/meeting-not-found/meeting-not-found.component';
import { MeetingPasswordRequiredComponent } from './modules/meeting-password-required/meeting-password-required.component';
import { ServiceUnavailableComponent } from './modules/service-unavailable/service-unavailable.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    JoinMeetingComponent,
    MeetingHeaderComponent,
    MeetingFormComponent,
    MeetingNotFoundComponent,
    MeetingNotAuthorizedComponent,
    MeetingPasswordRequiredComponent,
    ServiceUnavailableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      useCookiesForTransactions: true,
      authorizationParams: {
        redirect_uri: environment.auth0.redirectUrl,
        audience: environment.auth0.audience,
        scope: environment.auth0.scope
      },
      errorPath: '/service-unavailable',
      httpInterceptor: {
        allowedList: [
          {
            /* The endpoint you would like to prefix the access token with */
            uri: `${environment.apiURL}/*`,
            allowAnonymous: true
          },
          {
            uri: `${environment.apiV2URL}/*`,
            allowAnonymous: true
          }
        ]
      }
    }),
    FormsModule,
    TooltipModule
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: Window,
      useValue: window
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
