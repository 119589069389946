<div class="max-w-[960px] mx-auto">
  <div class="meeting-not-found">
    <h2>Meeting Not Found</h2>
    <div>
      <p>Sorry, we couldn't find the meeting you were looking for or the meeting has ended.</p>
      <p>Please check the meeting ID and try again.</p>
      <p>If you continue to have issues, please <a
          href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4" target="_blank">contact support</a>.
      </p>
    </div>
  </div>
  <lfx-footer></lfx-footer>
</div>
