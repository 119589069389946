<div class="max-w-[960px] mx-auto">
  <div class="meeting-service-unavailable">
    <h2>Service Unavailable</h2>
    <div>
      <p>You can still join an LFX meeting by clicking on "Join Meeting" in your zoom client, and manually putting in
        the
        meeting id and the passcode that are on your calendar invite for the meeting.</p>
      <p>If you continue to have issues, please <a
          href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4" target="_blank">contact support</a>.
      </p>
    </div>
  </div>
  <lfx-footer></lfx-footer>
</div>
