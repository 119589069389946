<div class="max-w-[960px] mx-auto">
  <div class="meeting-not-authorized">
    <h2>Private Meeting</h2>
    <div>
      <p>This meeting is private and only allows invited participants. If you received an invite, and are still getting
        this error, please ensure the invited email address is a verified email on your account: <a
          href="https://openprofile.dev" target="_blank">https://openprofile.dev</a>.</p>
      <p>If you continue to have issues, please <a
          href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4" target="_blank">contact support</a>.
      </p>
    </div>
  </div>
  <lfx-footer></lfx-footer>
</div>
