<div class="max-w-[960px] mx-auto">
  <div class="meeting-password-required">
    <h2>Password Required</h2>
    <div>
      <p>Sorry, the meeting you are attempting to join requires a password.</p>
      <p>Please try again with the invite link that was sent to your email, or contact the meeting organizer to have
        your
        invite resent.</p>
      <p>If you continue to have issues, please <a
          href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4" target="_blank">contact support</a>.
      </p>
    </div>
  </div>
  <lfx-footer></lfx-footer>
</div>
